import React from "react"
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet"
import L from "leaflet"
import "leaflet/dist/leaflet.css"

const Map = () => {
  if (typeof window !== `undefined`) {
    const icon = L.icon({
      iconUrl: "../images/map-icon.png",
      iconSize: [15, 15],
    })
    const hqIcon = L.icon({
      iconUrl: "../images/hq-map-icon.png",
      iconSize: [30, 30],
    })

    return (
      <div>
        <MapContainer
          center={[51.505, -0.09]}
          zoom={2}
          style={{ height: "650px" }}
          zoomControl={false}
          doubleClickZoom={false}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://api.mapbox.com/styles/v1/sedyldz/ckyn1zior1fr214tf3pnxjwxw/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2VkeWxkeiIsImEiOiJja2IxYXNyc2YwMDlzMnJzNXpyNGpjMzkyIn0.9EgK_5CjBI1HuaQa0_qKeA"
          />

          <Marker className="map-marker" position={[29.64512999645449, -98.49175883343355]} icon={hqIcon}>
            <Tooltip className="text-black font-bold font-sans">San Antonio</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[29.645120671962765, -98.49175883343355]} icon={hqIcon}>
            <Tooltip className="text-black font-bold font-sans">San Antonio</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[29.42891358228453, -98.49374181994453]} icon={hqIcon}>
            <Tooltip className="text-black font-bold font-sans">San Antonio</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[29.42891358228453, -98.49374181994453]} icon={hqIcon}>
            <Tooltip className="text-black font-bold font-sans">San Antonio</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[51.52027677335042, -0.18168985976446478]} icon={icon}>
            <Tooltip className="text-black font-bold font-sans">London</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[-36.84571606433368, 174.77101139582638]} icon={icon}>
            <Tooltip className="text-black font-bold font-sans">Auckland</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[59.92206877711103, 10.68966307864463]} icon={icon}>
            <Tooltip className="text-black font-bold font-sans">Oslo</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[59.30957918155778, 18.028568042391765]} icon={icon}>
            <Tooltip className="text-black font-bold font-sans">Stockholm</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[-33.822104224704034, 151.19687991291855]} icon={icon}>
            <Tooltip className="text-black font-bold font-sans">St Leonards</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[55.661771370347154, 12.398030196218231]} icon={icon}>
            <Tooltip className="text-black font-bold font-sans">Glostrup</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[53.40881918053416, -6.235770273186592]} icon={icon}>
            <Tooltip className="text-black font-bold font-sans">Dublin</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[52.37714576538581, 5.213722740267744]} icon={icon}>
            <Tooltip className="text-black font-bold font-sans">Almere</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[34.04519895332278, -118.3046568363002]} icon={icon}>
            <Tooltip className="text-black font-bold font-sans">Los Angeles</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[43.95181160045442, -72.05678535379728]} icon={icon}>
            <Tooltip className="text-black font-bold font-sans">Marlborough</Tooltip>
          </Marker>
          <Marker className="map-marker" position={[42.48648525029754, -71.14480360246485]} icon={icon}>
            <Tooltip className="text-black font-bold font-sans">Woburn</Tooltip>
          </Marker>
        </MapContainer>
      </div>
    )
  }
  return null
}

export default Map
