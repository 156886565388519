import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Map from "../components/map"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const OurCompanies = ({ data }) => {
  const companies = data.allContentfulCompanies.nodes
  const companyPage = data.allContentfulCompanyPage.nodes[0]

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <b className="font-bold">{text}</b>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <a href={uri} className="underline">
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 data-sal="slide-left" data-sal-duration="1000" data-sal-delay="300" className="text-2xl lg:text-3xl font-bold text-black pb-5">{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 data-sal="slide-left" data-sal-duration="1000" data-sal-delay="300" className="text-2xl lg:text-3xl font-bold text-black pb-5">{children}</h3>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p data-sal="slide-left" data-sal-duration="1000" data-sal-delay="300" className="whitespace-pre-line font-serif text-black text-xl">
          {children}
        </p>
      ),
    },
  }

  return (
    <Layout>
      <Seo title={"Our companies"} description={"Companies of Dryline Partners"} />
      <div className="container mx-auto ">
        <div className="bg-white pt-5 lg:py-20">
          <div className="container p-5 relative">
            <span data-sal="slide-left" data-sal-duration="1000" className="text-purple pb-20 block">
              {companyPage.subtitle}
            </span>
            <div className="flex flex-col lg:flex-row items-start ">
              <div className="text-left lg:w-1/2 pr-10">
                <h1 data-sal="slide-left" data-sal-duration="1000" data-sal-delay="200" className="text-3xl lg:text-5xl font-bold text-black pb-20">
                  {companyPage.title}
                </h1>
                {renderRichText(companyPage.description, options)}
              </div>
              <div data-sal="fade" data-sal-duration="1000" data-sal-delay="400" className="lg:w-1/2">
                <StaticImage
                  className="lg:-top-28"
                  src="../images/our-companies.jpg"
                  alt="Our Companies"
                />
              </div>
            </div>
          </div>
          <div className="leaflet-container">
            {/*<StaticImage  src="../images/companies-map.png"  alt="Our companies" />*/}
            <Map />
          </div>
        </div>
      </div>
      <div className="team-grid w-full grid grid-cols-2 lg:grid-cols-4">
        {companies.map((company, index) => {
          const image = getImage(company.logo)
          return (
            <a
              className="font-serif border border-peach p-5 lg:p-20 h-40 lg:h-80 flex items-center w-full"
              key={index}
              href={company.link}
              rel="noreferrer"
              target="_blank"
            >
              <GatsbyImage
                data-sal="fade"
                data-sal-delay={index * 100}
                data-sal-duration="1000"
                image={image}
                alt={company.name}
              />
            </a>
          )
        })}
      </div>
    </Layout>
  )
}

export default OurCompanies

export const pageQuery = graphql`
  query CompaniesQuery {
    allContentfulCompanyPage {
      nodes {
        title
        subtitle
        description {
          raw
        }
      }
    }
    allContentfulCompanies(sort: { fields: order }) {
      nodes {
        name
        link
        logo {
          gatsbyImageData(width: 400)
        }
      }
    }
  }
`
